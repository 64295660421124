// footer
import twitter from '../assets/footerLogo/twitter.png';
import medium from '../assets/footerLogo/medium.png';
import discode from '../assets/footerLogo/discode.png';

import twitterWhite from "../assets/footerLogo/twitterWhite.png";
import mediumWhite from "../assets/footerLogo/mediumWhite.png";
import discodeWhite from "../assets/footerLogo/discodeWhite.png";

const footer_blackLogos={
    "twitter":twitter,
    "discode":discode,
    "medium":medium
};
const footer_whiteLogos={
    "twitter":twitterWhite,
    "discode":discodeWhite,
    "medium":mediumWhite
};

const target = process.env.REACT_APP_TARGET;

const tatget_white=["zksync","base",'polygon','linea','manta'];


export const footer_logolist=(()=>{
  if(tatget_white.includes(target)){
    return footer_whiteLogos;
  }
  if(target==="testnet"){
    return footer_blackLogos;
  }
   return footer_whiteLogos;
})()
